/** @jsx jsx */
import { css } from '@emotion/react'

export const formSectionStyles = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 72px;
    margin-bottom: 32px;
    h2 {
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 16px;
      @media (max-width: 500px) {
        text-align: center;
      }
    }
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 32px;
      @media (max-width: 850px) {
        text-align: center;
      }
    }
  }
  .form-container {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  .form-div {
    background-color: #412a88;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    width: 540px;
  }
  form {
    background-color: #412a88;
    display: flex;
    flex-direction: column;
    width: 350px;
    margin: 92px 0 72px 0;
    gap: 19px;
    align-items: center;
    
    button {
      background-color: #6c63ff;
      border: none;
      display: flex;
      padding: 12px 22px;
      justify-content: center;
      align-items: center;
      width: fit-content;
      align-self: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      color: #f9f9f9;
      border-radius: 4px;
    }
    input {
      border: none;
      border-radius: 8px;
      width: 100%;
      height: 54px;
      position: relative;

      &::placeholder {
        font-size: 18px;
        font-family: Montserrat;
        color: #412a88;
        font-weight: 700;
        line-height: 120%;
        padding-left: 16px;
      }
    }
    textarea {
      border: none;
      border-radius: 8px;
      width: 100%;
      height: 54px;
      position: relative;

      &::placeholder {
        font-size: 18px;
        font-family: Montserrat;
        color: #412a88;
        font-weight: 700;
        line-height: 120%;
        padding-left: 16px;
      }

      &::placeholder {
        position: absolute;
        top: 16px;
        left: 0;
      }
    }
    #mensagem {
      height: 110px;
    }
  }
  .app-buttons {
    margin-top: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px) {
      margin-top: 56px;
    }

    .buttons {
      border-radius: 32px;
      background-color: #2b1961;
      width: 730px;
      height: 224px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #f9f9f9;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 500px) {
        p {
          padding: 0 36px;
          margin-bottom: 24px;
        }
        width: 343px;
        font-size: 32px;
        height: auto;
        padding: 56px 0px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .div-buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 24px;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
`
