/** @jsx jsx */
import { Col, Container, Row, Form, Button } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'
import { blockQuote } from '../assets/styles/Blockquote.styles'

import profWeb from '../assets/images/LP-images/prof-web.png'
import profApp from '../assets/images/LP-images/prof-app-2.png'
import profDesktop from '../assets/images/LP-images/prof-web-home.png'

import profWebMobile from '../assets/images/LP-images/prof-home-mobile.png'
import profAppMobile from '../assets/images/LP-images/prof-app-mobile.png'
import profDesktopMobile from '../assets/images/LP-images/prof-desktop-mobile.png'

const Blockquote = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <section id='conheça-o-prof' css={blockQuote}>
      <Container data-aos='fade-down' className='titleContainer'>
        <h2>Transforme a rotina escolar em uma jornada digital</h2>
        <p>
          Imagine ter uma ferramenta poderosa que auxilia você em cada etapa do
          ensino. O Prof permite a criação de aulas dinâmicas, adaptação de
          materiais personalizados e gestão completa do pedagógico.
        </p>
      </Container>
      <Container className='customContainer'>
        <Row>
          <Col
            data-aos-offset='300'
            data-aos-easing='ease-in-sine'
            data-aos='fade-right'
            className='content-image'
            lg={6}
          >
            <img
              className='prof-web'
              src={isTabletOrMobile ? profWebMobile : profWeb}
              alt=''
            />
          </Col>
          <Col
            data-aos='fade-left'
            data-aos-offset='500'
            data-aos-duration='500'
            className='text-col'
            lg={6}
          >
            <div className='text'>
              <h3>PROFESSOR</h3>
              <p>
                Materiais customizados de forma fácil e rápida, utilizando
                conteúdos prontos ou criações próprias. Otimização do tempo ao
                simplificar a preparação de lista de exercícios, provas e
                simulados.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className='customContainer'>
        <Row className='row-reverse'>
          <Col className='content-image' lg={6}>
            <img
              data-aos-offset='300'
              data-aos-easing='ease-in-sine'
              data-aos='fade-left'
              className='prof-web'
              src={isTabletOrMobile ? profAppMobile : profApp}
              alt=''
            />
          </Col>
          <Col
            data-aos='fade-right'
            data-aos-offset='500'
            data-aos-duration='500'
            className='text-col'
            lg={6}
          >
            <div className='text'>
              <h3>ALUNO</h3>
              <p>
                Aulas personalizadas com conteúdos desenvolvidos para engajar
                durante a aprendizagem. Acompanhamento do desempenho via
                dashboard online, para identificar pontos fortes e áreas de
                melhoria.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className='customContainer'>
        <Row>
          <Col data-aos-offset='300'
              data-aos-easing='ease-in-sine'
              data-aos='fade-right' className='content-image' lg={6}>
            <img
              className='prof-web'
              src={isTabletOrMobile ? profDesktopMobile : profDesktop}
              alt=''
            />
          </Col>
          <Col data-aos='fade-left'
            data-aos-offset='300'
            data-aos-easing='ease-in-sine' className='text-col' lg={6}>
            <div className='text'>
              <h3>INSTITUIÇÃO</h3>
              <p>
                Disponível para todos os educadores e equipe pedagógica,
                independentemente da área de atuação. Melhoria no desempenho dos
                alunos e dos professores, com resultados mensuráveis e visíveis.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Blockquote
