/** @jsx jsx */
import { jsx } from '@emotion/react'

import { styles } from '../assets/styles/AccordionSection.styles'
import AccordionSection from './accordion'
import { Col, Container, Row } from 'react-bootstrap'

import avatar from '../assets/images/LP-images/avatar.png'

const Who = () => {
  return (
    <section id='entenda-o-prof' css={styles}>
      <Container>
        <Row className='customRow'>
          <Col className='customCol' lg={6}>
            <AccordionSection title='Crie uma plataforma 100% personalizada'>
            A tecnologia da plataforma white label para a educação permite aplicar a identidade visual da escola em um sistema próprio de ensino. Fortaleça o branding e tenha em um só lugar as possibilidades para simplificar a rotina escolar.
            </AccordionSection>
            <AccordionSection title='Engaje alunos e desenvolva a escola'>
            A solução para utilizar vários formatos de arquivos na elaboração de provas, simulados e atividades, como vídeo, gif, gráfico, imagem e texto. Acompanhe o desenvolvimento da qualidade do ensino e a execução do projeto pedagógico.
            </AccordionSection>
            <AccordionSection title='Gerencie conteúdos educacionais'>
            O ambiente ideal para uma aprendizagem estruturada. Resultado de uma curadoria de qualidade e do trabalho de professores renomados, o nosso Banco de Questões está disponível na plataforma para provas, simulados e testes. Organize conteúdos, crie bibliotecas e configure listas de estudos personalizadas.
            </AccordionSection>
          </Col>
          <Col className='avatarCol' lg={6}>
          <img className='avatar' src={avatar} alt="" />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Who
