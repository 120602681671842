/** @jsx jsx */
import { css } from "@emotion/react";

export const appleStyle = css`
    &:hover {
        cursor: pointer;
        path:first-child {
            fill: #BC9BFA;
        }
    }
    &:active {
        path:first-child {
            fill: #04AEC9;
        }
        path {
            fill:#F9F9F9;
        }
    }
`