import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import About from "@/components/about-two";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Routine from "@/components/blockquote";
import InfoSection from "@/components/infoSection";
import FormSection from "@/components/formSection";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Prof - Conheça o Prof">
          <HeaderOne />
          <About />
          <Routine />
          <InfoSection />
          <FormSection />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
