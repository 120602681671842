/** @jsx jsx */
import { css } from '@emotion/react'

export const supportSection = css`
  height: 272px;
  background-color: #f9f9f9;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 32px;
    width: 159px;
    @media (max-width: 500px) {
      width: 159px;
    }
  }
  span {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #312e2d;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
`
