/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { abAgency, pr79 } from '../assets/styles/AboutTwo.styles'

import profApp from '../assets/images/LP-images/prof-app.png'
import AppleStoreIcon from './apple-store-icon'
import GooglePlayStoreIcon from './google-play-store-icon'

const AboutTwo = () => {
  return (
      <section css={ abAgency }>
    <Container>
        <Row className='title-content'>
          <Col css={pr79} lg={6} md={12} sm={12} data-aos='fade-right'>
            <h1>Gestão e personalização do ensino</h1>
            <p>
              Automatize tarefas e acompanhe a performance de alunos e equipe
              pedagógica. Analise o crescimento das turmas, identifique áreas de
              conhecimento estimuladas e garanta que os planos de aula estejam
              alinhados com as exigências pedagógicas.
            </p>
          </Col>
          <Col
            className='img-container'
            data-aos='fade-left'
            lg={6}
            md={12}
            sm={12}
          >
            <img src={profApp} alt='Calendario prof no app para celular' />
          </Col>
        </Row>
      </Container>
      <Container className='app-buttons'>
        <Row>
          <div className='buttons' data-aos='fade-up'>
            <p>Tenha o PROF na sua escola </p>
            <div className='div-buttons'>
              <GooglePlayStoreIcon />
              <AppleStoreIcon />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default AboutTwo
