/** @jsx jsx */
import { css } from "@emotion/react";

export const googleStyle = css`
&:hover {
    cursor: pointer;
    path:first-child {
        fill: #BC9BFA;
    }
}

&:active {
    path:first-child {
        fill: #04AEC9;
    }
    path:nth-child(2) {
        fill:#F9F9F9;
    }
}
`