/** @jsx jsx */
import { jsx } from '@emotion/react'

import { formSectionStyles } from '../assets/styles/FormSection.styles'
import { Container, Row } from 'react-bootstrap'
import { useState } from 'react'
import AppleStoreIcon from './apple-store-icon'
import GooglePlayStoreIcon from './google-play-store-icon'

const HowToWork = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [instituicao, setInstituicao] = useState('')
  const [telefone, setTelefone] = useState('')
  const [mensagem, setMensagem] = useState('')
  const handleSubmit = (event) => {
    event.preventDefault()
    setNome('')
    setEmail('')
    setInstituicao('')
    setTelefone('')
    setMensagem('')
  }
  return (
    <section css={formSectionStyles}>
      <Container className='title'>
        <h2>Quer o PROF na sua escola?</h2>
        <span>
          Preencha o formulário com suas informações e participe da nossa LISTA
          DE ESPERA
        </span>
      </Container>
      <Container className='form-container'>
        <div id='formulario' className='form-div'>
          <form onSubmit={handleSubmit}>
            <input
              type='text'
              id='nome'
              placeholder='Nome'
              name='nome'
              value={nome}
              onChange={(event) => setNome(event.target.value)}
              required
            />
            <input
              placeholder='Email'
              type='email'
              id='email'
              name='email'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <input
              placeholder='Instituição'
              type='text'
              id='instituicao'
              name='instituicao'
              value={instituicao}
              onChange={(event) => setInstituicao(event.target.value)}
              required
            />
            <input
              placeholder='Telefone'
              type='tel'
              id='telefone'
              name='telefone'
              value={telefone}
              onChange={(event) => setTelefone(event.target.value)}
              required
            />
            <textarea
              placeholder='Mensagem'
              id='mensagem'
              name='mensagem'
              value={mensagem}
              onChange={(event) => setMensagem(event.target.value)}
              required
            ></textarea>

            <button type='submit'>Enviar</button>
          </form>
        </div>
      </Container>
      <Container className='app-buttons'>
        <Row>
          <div className='buttons' data-aos='fade-up'>
            <p>Tenha o PROF na sua escola </p>
            <div className='div-buttons'>
              <GooglePlayStoreIcon />
              <AppleStoreIcon />
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default HowToWork
