/** @jsx jsx */
import { jsx } from '@emotion/react'
import { appleStyle } from '../assets/styles/AppleStoreIcon.styles'

const AppleStoreIcon = () => {
  return (
    <a href='https://apps.apple.com/us/app/prof/id1232498248'>
      <svg
        css={appleStyle}
        width='176'
        height='52'
        viewBox='0 0 176 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.80332 0H169.197C172.951 0 176 2.96181 176 6.60968V45.3903C176 49.0382 172.951 52 169.197 52H6.80332C3.04858 52 0 49.0382 0 45.3903V6.60968C0 2.96181 3.04858 0 6.80332 0Z'
          fill='#F9F9F9'
        />
        <path
          d='M10.9264 21.2475C8.07109 26.0605 9.88609 33.3655 13.0434 37.7944C14.6191 40.0092 16.2152 41.9965 18.4001 41.9965C18.4409 41.9965 18.4825 41.9958 18.5248 41.9937C19.552 41.954 20.2939 41.6471 21.011 41.3501C21.8163 41.0163 22.6502 40.6719 23.9567 40.6719C25.2632 40.6719 25.9927 41.0057 26.7594 41.3274C27.5159 41.6457 28.2943 41.9745 29.4455 41.9533C31.9068 41.9086 33.4183 39.764 34.7518 37.8716C36.1437 35.8964 36.8419 33.9786 37.0768 33.245L37.0862 33.216C37.1315 33.0855 37.0673 32.9438 36.9389 32.8864C36.9389 32.8864 36.9228 32.8793 36.9192 32.8779C36.4859 32.7057 32.683 31.0664 32.6429 26.5999C32.6056 22.9705 35.4952 21.0491 36.0751 20.7004L36.1021 20.6834C36.1649 20.6444 36.2086 20.582 36.224 20.5111C36.2386 20.4396 36.224 20.3659 36.1809 20.3056C34.1865 17.47 31.1291 17.0426 29.8985 16.9916C29.7205 16.9738 29.5359 16.9653 29.3506 16.9653C27.9047 16.9653 26.5202 17.4955 25.4069 17.9221C24.6388 18.2163 23.9757 18.4707 23.5183 18.4707C23.004 18.4707 22.3365 18.2134 21.5647 17.9157C20.531 17.5174 19.3601 17.066 18.1207 17.066C18.0908 17.066 18.0623 17.066 18.0339 17.0674C15.1516 17.1085 12.4277 18.7102 10.9264 21.2475Z'
          fill='#6C63FF'
        />
        <path
          d='M29.9012 9.18626C28.1562 9.255 26.0618 10.299 24.8107 11.7235C23.7464 12.9199 22.7083 14.9164 22.9819 16.9278C22.9994 17.0539 23.1052 17.1503 23.235 17.161C23.3532 17.1695 23.4728 17.1744 23.5925 17.1744C25.2988 17.1744 27.14 16.2566 28.3977 14.7796C29.721 13.2204 30.39 11.2189 30.1879 9.42581C30.1718 9.28477 30.0442 9.18059 29.9012 9.18626Z'
          fill='#6C63FF'
        />
        <path
          d='M58.8271 35.1372H51.6802L50.2715 38.3506H47.5352L53.9672 24.4644H56.581L63.0334 38.3506H60.2562L58.8271 35.1372ZM57.9488 33.1138L55.2533 27.0434L52.5782 33.1138H57.9488Z'
          fill='#6C63FF'
        />
        <path
          d='M73.4971 28.3132C74.3346 28.7632 74.9911 29.3975 75.4675 30.2175C75.9438 31.0376 76.1824 31.983 76.1824 33.0546C76.1824 34.1262 75.9438 35.0752 75.4675 35.9016C74.9911 36.728 74.3338 37.3666 73.4971 37.8159C72.6596 38.266 71.7106 38.4906 70.6484 38.4906C69.1785 38.4906 68.0142 38.0144 67.157 37.0625V42.2009H64.6045V27.7589H67.0345V29.1474C67.4561 28.6449 67.977 28.2678 68.5963 28.0162C69.2157 27.7653 69.8999 27.6392 70.6484 27.6392C71.7106 27.6392 72.6596 27.8645 73.4971 28.3139V28.3132ZM72.6801 35.4551C73.2855 34.847 73.589 34.0468 73.589 33.0546C73.589 32.0624 73.2863 31.2629 72.6801 30.6541C72.0738 30.046 71.302 29.7413 70.3624 29.7413C69.7497 29.7413 69.1989 29.8773 68.7087 30.1481C68.2184 30.4195 67.8303 30.8065 67.5451 31.309C67.2591 31.8115 67.1162 32.3934 67.1162 33.0546C67.1162 33.7159 67.2591 34.2977 67.5451 34.8002C67.8311 35.3027 68.2192 35.6897 68.7087 35.9611C69.1989 36.2326 69.7497 36.368 70.3624 36.368C71.302 36.368 72.0738 36.0639 72.6801 35.4551Z'
          fill='#6C63FF'
        />
        <path
          d='M87.5059 28.3132C88.3434 28.7632 88.9999 29.3975 89.4763 30.2175C89.9526 31.0376 90.1912 31.983 90.1912 33.0546C90.1912 34.1262 89.9526 35.0752 89.4763 35.9016C88.9999 36.728 88.3426 37.3666 87.5059 37.8159C86.6684 38.266 85.7193 38.4906 84.6572 38.4906C83.1872 38.4906 82.023 38.0144 81.1658 37.0625V42.2009H78.6133V27.7589H81.0432V29.1474C81.4649 28.6449 81.9858 28.2678 82.6051 28.0162C83.2245 27.7653 83.9087 27.6392 84.6572 27.6392C85.7193 27.6392 86.6684 27.8645 87.5059 28.3139V28.3132ZM86.6888 35.4551C87.2943 34.847 87.5978 34.0468 87.5978 33.0546C87.5978 32.0624 87.2951 31.2629 86.6888 30.6541C86.0826 30.046 85.3108 29.7413 84.3712 29.7413C83.7584 29.7413 83.2077 29.8773 82.7175 30.1481C82.2272 30.4195 81.8391 30.8065 81.5539 31.309C81.2679 31.8115 81.1249 32.3934 81.1249 33.0546C81.1249 33.7159 81.2679 34.2977 81.5539 34.8002C81.8399 35.3027 82.228 35.6897 82.7175 35.9611C83.2077 36.2326 83.7584 36.368 84.3712 36.368C85.3108 36.368 86.0826 36.0639 86.6888 35.4551Z'
          fill='#6C63FF'
        />
        <path
          d='M99.7361 38.0936C98.7082 37.7895 97.8948 37.3862 97.2959 36.8837L98.2151 34.8801C98.8001 35.3302 99.5187 35.6966 100.369 35.9808C101.22 36.2657 102.074 36.4075 102.932 36.4075C103.993 36.4075 104.786 36.2423 105.311 35.9114C105.835 35.5811 106.097 35.1445 106.097 34.6023C106.097 34.2054 105.951 33.878 105.658 33.62C105.366 33.362 104.994 33.1608 104.546 33.0148C104.096 32.8695 103.484 32.7043 102.708 32.5186C101.619 32.2677 100.738 32.0161 100.064 31.7645C99.3896 31.5137 98.8111 31.1196 98.3281 30.5845C97.8445 30.0487 97.603 29.3251 97.603 28.4122C97.603 27.6454 97.8175 26.9473 98.2464 26.3193C98.6754 25.6914 99.3217 25.1917 100.186 24.8218C101.051 24.4518 102.108 24.2661 103.362 24.2661C104.233 24.2661 105.091 24.3724 105.935 24.5836C106.779 24.7955 107.507 25.0996 108.119 25.4965L107.282 27.5001C106.655 27.1429 106.002 26.8721 105.322 26.6864C104.641 26.5015 103.981 26.4086 103.341 26.4086C102.293 26.4086 101.514 26.5808 101.003 26.9246C100.493 27.2683 100.237 27.7247 100.237 28.2932C100.237 28.69 100.383 29.0139 100.676 29.2648C100.969 29.5164 101.34 29.7149 101.789 29.8602C102.238 30.0062 102.85 30.1713 103.627 30.3563C104.689 30.5944 105.56 30.8425 106.24 31.1005C106.921 31.3584 107.503 31.7518 107.986 32.2805C108.469 32.8099 108.711 33.5236 108.711 34.423C108.711 35.1899 108.497 35.8844 108.068 36.506C107.639 37.1276 106.989 37.6237 106.118 37.9936C105.247 38.3636 104.185 38.5493 102.933 38.5493C101.83 38.5493 100.765 38.3969 99.7368 38.0929L99.7361 38.0936Z'
          fill='#6C63FF'
        />
        <path
          d='M117.653 37.7762C117.353 38.0143 116.989 38.1929 116.56 38.312C116.132 38.431 115.679 38.4906 115.203 38.4906C114.005 38.4906 113.079 38.1865 112.426 37.5777C111.772 36.9696 111.445 36.0837 111.445 34.9192V29.8206H109.648V27.8368H111.445V25.4165H113.998V27.8368H116.918V29.8206H113.998V34.8597C113.998 35.3757 114.127 35.769 114.386 36.0398C114.644 36.3112 115.019 36.4466 115.509 36.4466C116.08 36.4466 116.557 36.3013 116.938 36.01L117.653 37.7755V37.7762Z'
          fill='#6C63FF'
        />
        <path
          d='M121.308 37.7958C120.423 37.333 119.733 36.688 119.236 35.8617C118.739 35.0353 118.49 34.0997 118.49 33.0543C118.49 32.009 118.738 31.0777 119.236 30.257C119.733 29.437 120.423 28.7955 121.308 28.3327C122.193 27.8699 123.187 27.6382 124.29 27.6382C125.393 27.6382 126.406 27.8699 127.292 28.3327C128.177 28.7955 128.867 29.437 129.364 30.257C129.861 31.077 130.11 32.0097 130.11 33.0543C130.11 34.099 129.861 35.0353 129.364 35.8617C128.867 36.688 128.177 37.333 127.292 37.7958C126.407 38.2586 125.406 38.4904 124.29 38.4904C123.174 38.4904 122.193 38.2593 121.308 37.7958ZM126.618 35.4548C127.23 34.8467 127.537 34.0466 127.537 33.0543C127.537 32.0621 127.23 31.2627 126.618 30.6539C126.005 30.0458 125.229 29.741 124.29 29.741C123.35 29.741 122.578 30.0451 121.972 30.6539C121.366 31.262 121.063 32.0621 121.063 33.0543C121.063 34.0466 121.366 34.8467 121.972 35.4548C122.578 36.0629 123.35 36.3677 124.29 36.3677C125.229 36.3677 126.005 36.0636 126.618 35.4548Z'
          fill='#6C63FF'
        />
        <path
          d='M138.87 27.6392V30C138.652 29.9603 138.455 29.9404 138.277 29.9404C137.284 29.9404 136.508 30.2218 135.95 30.7838C135.391 31.3459 135.112 32.1559 135.112 33.2141V38.3524H132.56V27.7589H134.99V29.3061C135.725 28.1955 137.018 27.6399 138.869 27.6399L138.87 27.6392Z'
          fill='#6C63FF'
        />
        <path
          d='M151.141 33.8481H142.585C142.735 34.6285 143.127 35.2465 143.759 35.7029C144.392 36.1593 145.178 36.3875 146.118 36.3875C147.316 36.3875 148.303 36.0041 149.079 35.2373L150.447 36.7646C149.956 37.3337 149.337 37.7632 148.589 38.0538C147.84 38.3451 146.996 38.4904 146.056 38.4904C144.859 38.4904 143.803 38.2593 142.891 37.7958C141.979 37.333 141.275 36.688 140.778 35.8617C140.281 35.0353 140.032 34.0997 140.032 33.0543C140.032 32.009 140.274 31.094 140.757 30.2669C141.24 29.4405 141.911 28.7955 142.769 28.3327C143.626 27.8699 144.592 27.6382 145.668 27.6382C146.744 27.6382 147.68 27.8664 148.517 28.3228C149.355 28.7792 150.007 29.4207 150.477 30.247C150.947 31.0734 151.182 32.0295 151.182 33.1139C151.182 33.2861 151.168 33.5306 151.141 33.8481ZM143.596 30.3271C143.03 30.797 142.688 31.4214 142.565 32.2017H148.752C148.643 31.4349 148.309 30.8133 147.751 30.3371C147.193 29.8608 146.499 29.6226 145.668 29.6226C144.838 29.6226 144.16 29.8572 143.596 30.3271Z'
          fill='#6C63FF'
        />
        <path
          d='M48.4678 11.4996C49.1404 11.4011 49.9406 11.3267 50.8168 11.3267C52.4042 11.3267 53.5342 11.6846 54.2833 12.3628C55.0449 13.0411 55.4899 14.0036 55.4899 15.348C55.4899 16.6925 55.0581 17.8151 54.2585 18.5799C53.4583 19.3566 52.1379 19.7762 50.4746 19.7762C49.6875 19.7762 49.0273 19.7394 48.4685 19.6777V11.4989L48.4678 11.4996ZM49.5722 18.852C49.8516 18.9016 50.258 18.9137 50.6898 18.9137C53.0512 18.9137 54.3337 17.6309 54.3337 15.3856C54.3461 13.4245 53.2037 12.1786 50.8671 12.1786C50.2959 12.1786 49.864 12.2282 49.5722 12.2898V18.8527V18.852Z'
          fill='#6C63FF'
        />
        <path
          d='M58.1309 12.0552C58.1433 12.4252 57.8639 12.7214 57.4197 12.7214C57.0257 12.7214 56.7471 12.4252 56.7471 12.0552C56.7471 11.6853 57.0389 11.377 57.4452 11.377C57.8515 11.377 58.1309 11.6732 58.1309 12.0552ZM56.8864 19.7039V13.7335H58.004V19.7039H56.8864Z'
          fill='#6C63FF'
        />
        <path
          d='M59.704 18.5933C60.0344 18.8031 60.618 19.0249 61.1768 19.0249C61.9895 19.0249 62.3703 18.6301 62.3703 18.1368C62.3703 17.6188 62.0529 17.3353 61.2272 17.039C60.1227 16.6563 59.6018 16.0645 59.6018 15.3487C59.6018 14.3862 60.4021 13.5967 61.7225 13.5967C62.3447 13.5967 62.8904 13.7696 63.2333 13.9666L62.9539 14.7562C62.7124 14.608 62.2681 14.411 61.6969 14.411C61.0368 14.411 60.6684 14.781 60.6684 15.2254C60.6684 15.7186 61.0368 15.9412 61.8363 16.2367C62.9028 16.6315 63.4485 17.1496 63.4485 18.0376C63.4485 19.0858 62.6103 19.8265 61.1506 19.8265C60.478 19.8265 59.8557 19.6663 59.4238 19.4197L59.7032 18.5933H59.704Z'
          fill='#6C63FF'
        />
        <path
          d='M64.886 15.6816C64.886 14.9168 64.8605 14.2995 64.835 13.7325H65.838L65.8891 14.7567H65.9146C66.372 14.0288 67.0957 13.5972 68.0987 13.5972C69.5847 13.5972 70.7016 14.8183 70.7016 16.632C70.7016 18.7787 69.3557 19.839 67.9083 19.839C67.0957 19.839 66.3844 19.4939 66.0168 18.9014H65.9912V22.1459H64.8868V15.6816H64.886ZM65.9905 17.2727C65.9905 17.4328 66.016 17.581 66.0416 17.7171C66.2451 18.4697 66.9177 18.9878 67.7179 18.9878C68.899 18.9878 69.5847 18.0502 69.5847 16.6809C69.5847 15.4845 68.9369 14.4604 67.7566 14.4604C66.995 14.4604 66.2838 14.9905 66.0678 15.8049C66.0299 15.941 65.9919 16.1011 65.9919 16.2493V17.2734L65.9905 17.2727Z'
          fill='#6C63FF'
        />
        <path
          d='M77.671 16.6683C77.671 18.8768 76.0968 19.8385 74.6108 19.8385C72.9475 19.8385 71.665 18.6542 71.665 16.7669C71.665 14.7682 73.011 13.5967 74.7122 13.5967C76.4134 13.5967 77.671 14.8426 77.671 16.6683ZM72.795 16.73C72.795 18.0376 73.5698 19.0242 74.6618 19.0242C75.7539 19.0242 76.5286 18.0497 76.5286 16.7052C76.5286 15.6938 76.0078 14.4103 74.6874 14.4103C73.367 14.4103 72.7958 15.5946 72.7958 16.7293L72.795 16.73Z'
          fill='#6C63FF'
        />
        <path
          d='M79.0804 15.3487C79.0804 14.7321 79.068 14.226 79.0293 13.7328H80.02L80.0834 14.7193H80.109C80.4139 14.1516 81.1244 13.5967 82.1406 13.5967C82.9912 13.5967 84.3116 14.09 84.3116 16.1382V19.7032H83.194V16.2615C83.194 15.2991 82.8256 14.4975 81.7722 14.4975C81.0354 14.4975 80.4642 15.0035 80.2738 15.6081C80.2228 15.7442 80.198 15.9291 80.198 16.1141V19.7039H79.0804V15.3494V15.3487Z'
          fill='#6C63FF'
        />
        <path
          d='M88.4008 11.1543L86.8513 12.9183H86.0518L87.1694 11.1543H88.4008ZM86.1276 19.7031V13.7327H87.2452V19.7031H86.1276Z'
          fill='#6C63FF'
        />
        <path
          d='M89.5304 13.7324L90.737 17.0876C90.9405 17.6305 91.1054 18.1238 91.2323 18.617H91.2702C91.4103 18.1238 91.5876 17.6305 91.7911 17.0876L92.9846 13.7324H94.1525L91.74 19.7028H90.6735L88.3369 13.7324H89.5304Z'
          fill='#6C63FF'
        />
        <path
          d='M95.702 16.9157C95.7276 18.3835 96.6927 18.988 97.8096 18.988C98.6098 18.988 99.092 18.8527 99.5107 18.6797L99.7011 19.4565C99.3072 19.6294 98.6346 19.8265 97.6571 19.8265C95.7655 19.8265 94.6348 18.6174 94.6348 16.8165C94.6348 15.0156 95.7268 13.5967 97.517 13.5967C99.5231 13.5967 100.056 15.3111 100.056 16.4089C100.056 16.6308 100.031 16.8037 100.018 16.915H95.7013L95.702 16.9157ZM98.9782 16.1382C98.9906 15.4472 98.6864 14.3742 97.4288 14.3742C96.2988 14.3742 95.8034 15.3855 95.7144 16.1382H98.9775H98.9782Z'
          fill='#6C63FF'
        />
        <path
          d='M101.441 10.9443H102.559V19.7029H101.441V10.9443Z'
          fill='#6C63FF'
        />
        <path
          d='M107.129 15.3487C107.129 14.7321 107.117 14.226 107.078 13.7328H108.069L108.132 14.7193H108.158C108.463 14.1516 109.173 13.5967 110.189 13.5967C111.04 13.5967 112.36 14.09 112.36 16.1382V19.7032H111.243V16.2615C111.243 15.2991 110.874 14.4975 109.821 14.4975C109.084 14.4975 108.513 15.0035 108.323 15.6081C108.272 15.7442 108.247 15.9291 108.247 16.1141V19.7039H107.129V15.3494V15.3487Z'
          fill='#6C63FF'
        />
        <path
          d='M117.58 19.7029L117.491 18.9503H117.453C117.11 19.4187 116.45 19.8383 115.574 19.8383C114.329 19.8383 113.694 18.9871 113.694 18.1239C113.694 16.6809 115.015 15.8913 117.389 15.9034V15.7801C117.389 15.2868 117.25 14.3987 115.992 14.3987C115.421 14.3987 114.824 14.5717 114.392 14.8431L114.139 14.1273C114.646 13.8062 115.383 13.5972 116.158 13.5972C118.037 13.5972 118.494 14.8431 118.494 16.0395V18.272C118.494 18.7901 118.52 19.2961 118.596 19.7029H117.58H117.58ZM117.415 16.6561C116.196 16.6313 114.812 16.8411 114.812 18.0005C114.812 18.7036 115.294 19.0367 115.866 19.0367C116.666 19.0367 117.174 18.5434 117.352 18.0374C117.39 17.9261 117.416 17.8028 117.416 17.6922V16.6561H117.415Z'
          fill='#6C63FF'
        />
      </svg>
    </a>
  )
}

export default AppleStoreIcon
