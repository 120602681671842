/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useContext } from 'react'
import {
  Accordion as BootstrapAccordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from 'react-bootstrap'
import { Button, accordion, hrStyle, icon } from '../assets/styles/Accordion.styles'

function ContextAwareToggle({ eventKey, callback, title }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Button
      type='button'
      onClick={decoratedOnClick}
      isShowing={isCurrentEventKey}
    >
      <div className='title'>
        <h3>{title}</h3>
        <span css={icon}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
          >
            <path
              d='M24.3848 15.1233L16.0864 23.5498C15.8523 23.7876 15.4775 23.7852 15.2501 23.5445L7.0513 15.012'
              stroke='white'
              stroke-width='1.5'
              stroke-miterlimit='10'
              stroke-linecap='square'
            />
            <path
              d='M15.6973 23.0146L15.7851 8.05589'
              stroke='white'
              stroke-width='1.5'
              stroke-miterlimit='10'
              stroke-linecap='square'
            />
          </svg>
        </span>
      </div>

      
    </Button>
  )
}

function AccordionSection({ title, children }) {
  return (
    <BootstrapAccordion css={accordion} defaultActiveKey='1'>
      <Card className='card'>
        <Card.Header className='card__header'>
          <ContextAwareToggle eventKey='0' title={title} />
        </Card.Header>
        <BootstrapAccordion.Collapse eventKey='0'>
          <Card.Body className='card__body'>{children}</Card.Body>
        </BootstrapAccordion.Collapse>
        <hr css={hrStyle} />
      </Card>
    </BootstrapAccordion>
  )
}

export default AccordionSection
