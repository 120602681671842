/** @jsx jsx */
import { css } from '@emotion/react'

export const styles = css`
  display: flex;
  background-color: #412a88;
  margin-top: 56px;

  .customCol {
    margin-top: 60px;
    @media (max-width: 850px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .customRow {
  }
  .avatarCol {
    @media (min-width: 500px) and (max-width: 850px) {
        display: flex;
        justify-content: center;
    }
  }
  .avatar {
    @media (max-width: 500px) {
      width: 100%;
      transform: scaleX(-1);
    }
    @media (min-width: 850px) {
        position: absolute;
        bottom: 0;
    }
  }
`
