/** @jsx jsx */
import { jsx } from '@emotion/react'

import { supportSection } from '../assets/styles/Support.styles'
import { commonSection } from '../assets/styles/layout.styles'

import logo from '../assets/images/LP-images/logo-fractal.svg'

const Footer = () => {
  return (
    <section data-aos='fade-up' css={[commonSection, supportSection]}>
      <img src={logo} alt='Logo fractal, rethink reinvent, rebiz' />
      <span>@2023 FractalTecnologia. Alguns direitos reservados.</span>
    </section>
  )
}

export default Footer
