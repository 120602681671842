/** @jsx jsx */
import { css } from '@emotion/react'

export const abAgency = css`
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  @media (max-width: 500px) {
    margin-top: 32px;
  }

  .app-buttons {
    margin-top: 61px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 500px) {
      margin-top: 56px;
    }

    .buttons {
      border-radius: 32px;
      background-color: #6c63ff;
      width: 730px;
      height: 224px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #f9f9f9;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
      @media (max-width: 500px) {
        p {
          padding: 0 36px;
          margin-bottom: 24px;
        }
        width: 343px;
        font-size: 32px;
        height: auto;
        padding: 56px 0px;
        margin-right: 16px;
        margin-left: 16px;
      }
    }

    .div-buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
      margin-top: 24px;
      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      }
    }
  }
  @media (max-width: 500px) {
    .container {
      padding: 0;
    }
  }
  .title-content {
    h1 {
      width: 420px;
      font-weight: 700;
      line-height: 100%;
      text-transform: uppercase;
      font-size: 40px;
      margin-bottom: 32px;
      @media (max-width: 850px) {
        width: 100%;
      }
    }
    p {
      color: #666;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    @media (max-width: 500px) {
      margin-top: 92px;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      margin-right: 1.5px;
      margin-left: 1.5px;
      h1 {
        font-size: 34px;
        width: 100%;
        text-align: left;
      }
      p {
        text-align: left;
      }
      img {
        margin-bottom: 56px;
        width: 350px;
      }
      .img-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: '';
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: auto;
    align-items: flex-start;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    text-align: center;
    height: auto;
    align-items: flex-start;
  }
`


