/** @jsx jsx */
import { css } from '@emotion/react'
import styled from '@emotion/styled'

export const icon = css`
  align-self: baseline;
  &:active {
    path {
      stroke: #BC9BFA;
    }
  }
  &:hover  {
    path {
      stroke: #BC9BFA;
    }
  }
`
export const accordion = css`
  font-weight: 400;

  .card {
    background: inherit;
    border: none;

    @media (min-width: 850px) {
      width: 445px;
      &:last-child {
        margin-bottom: 45px;
      }
    }

    .card-header {
      background-color: #412a88;
      border-bottom: none;
    }
    .card-body {
      padding: 0  0 48px 0;
    }
    &__header {
      padding: 0;
    }

    &__body {
      color: #f9f9f9;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      
    }
  }
`

export const Button = styled.button`
  border: none;
  background: transparent;
  color: #fff;
  text-align: left;
  padding: 0;
  margin-bottom: ${(props) => (props.isShowing ? '14.5px' : '25.8px')};

  @media (min-width: 500px) and (max-width: 850px) {
    width: 100% !important;
  }

  .title {
    align-items: center;
    justify-content:space-between;
    display: flex;
    gap: 15px;
    margin-bottom: ${(props) => (props.isShowing ? '0' : '13.46px')};

    @media (max-width: 850px) {
      margin-top: 48px;
    }
    h3 {
      color: #f9f9f9;
      font-size: 28px;
      font-weight: 700;
      line-height: 120%;
      
    }

    span {
      align-items: center;
      display: inline-flex;
      height: 25px;
      transform: ${(props) =>
        props.isShowing ? 'rotate(180deg)' : 'rotate(0deg)'};
      transition: all 0.3s ease-out;
      width: 25px;
      &:hover {
        .icon {
          color: #6743ff;
          transition: color 0.3s ease-out;
        }
      }
    }
  }

  .text {
    color: #fff;
    display: ${(props) => (props.isShowing ? 'none' : 'initial')};
    font-weight: 400 !important;

    p {
      color: #fff;
      line-height: 150%;
      margin: 0;
    }
  }

  @media (max-width: 900px) {
    margin-bottom: 16px;
  }
`

export const hrStyle = css`
  border: 1px solid #ffff;
  margin: 0;
`
