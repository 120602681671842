/** @jsx jsx */
import { css } from '@emotion/react'

export const blockQuote = css`
  margin-top: 96px;

  .container {
    @media (min-width: 850px) {
      margin-bottom: 73px;
    }
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    h2 {
      text-align: center;
      margin-bottom: 16px;
      font-size: 36px;
      font-weight: 700;
      line-height: 120%;
    }
    p {
      text-align: center;
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .content-image {
    @media (max-width: 850px) {
      display: flex;
      justify-content: center;
    }
  }
  .prof-web {
    @media (max-width: 500px) {
      width: 333px;
    }
  }
  .text {
    h3 {
      color: #412a88;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
    p {
      color: #666;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    @media (max-width: 500px) {
      h3 {
        margin-top: 24px;
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
  .text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
`
